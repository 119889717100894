<template lang="pug">
    Dialog.dialogVisualizar(:header="`${ model.ie_status == 'A' ? 'Validar' : 'Visualizar' } agendamento`" :visible.sync='$parent.dialogVisualizar' :modal='true')
        .ta-center(v-if="model && waitingDadosIds.includes(model.id)")
            ProgressSpinner.waiting-dados(strokeWidth='2')
        .panel-list(v-else)
            //p(v-if='model.cdGuiaEletronica') <b>Código da guia:</b> {{ model.cdGuiaEletronica }}
            .p-grid.p-fluid.mt-1(v-if='model.transacao_financeira')
                p.p-col-6 <b>Código da guia:</b> {{ model.transacao_financeira.cd_guia_procedimentos || '-'}}
                p.p-col-6(v-if='model.ie_status == "EX"') <b>Chave da guia:</b> {{ model.transacao_financeira.ds_hash }}
            p <b>Nome do paciente:</b> {{ model.nm_paciente }}
            p(v-if="$parent.filters.ie_tipo_agenda == 'E'") <b>Procedimento:</b> {{ model.nm_procedimento }}
            p <b>Data:</b> {{ model.dt_agenda }}
            p <b>Horário:</b> {{ model.hr_agenda_f }}
            p(v-if="$parent.filters.ie_tipo_agenda == 'C'") <b>Especialidade:</b> {{ model.ds_especialidade }}
            p(v-if="$parent.filters.ie_tipo_agenda == 'C'") <b>Especialista:</b> {{ model.ds_especialista }}
            .p-grid.p-fluid.mt-1(v-if='model.ie_telemedicina && model.ie_tipo_agenda =="C"')
                p.p-col-4 <b>Paciente Entrou:</b> {{  model.ie_paciente_entrou ? 'SIM' : 'NÃO' }}
                p.p-col-4 <b>Médico Entrou:</b> {{  model.ie_medico_entrou ? 'SIM' : 'NÃO' }}
                p.p-col-4 <b>Ligação:</b> {{ options.status_chamada[model.ie_status_chamada] }}
            p <b>Situação: </b>
                span.mtd-badge(:class="{ co3: model.ie_status == 'A', co5: model.ie_status == 'EX' }") {{ model.ie_status_f }}
        .p-grid.p-fluid.mt-4(v-if="!model || !waitingDadosIds.includes(model.id)")
            .p-col-12.ta-center(v-if='waiting')
                ProgressSpinner.waiting-imprimir(strokeWidth='6')
            .p-col-12(v-else-if="model.ie_status == 'A'")
                label.form-label Chave da guia:
                .p-inputgroup
                    InputText(v-model='dsRash')
                    Button.btn-executar.p-button-success(icon='jam jam-key' label='Executar' @click='executarGuia()')
            .p-col-12(v-else-if="model.ie_status == 'EX'")
                    Button.btn-executar.p-button-success(icon='jam jam-printer' label='Imprimir guia' @click='imprimirGuia()')
</template>

<style lang="scss">
    .agendamento-listar {
        .dialogVisualizar {
            .form-label {
                margin: 0;
                font-size: 12px;
            }
            .btn-executar .p-button-text {
                font-size: 12px;
            }
            @media all and (min-width: 577px) {
                min-width: 400px;
            }
            .waiting-imprimir {
                width: 31px;
                height: auto;
            }
            .waiting-dados {
                margin: 50px;
            }
        }
    }
</style>

<script>
    import Dialog from 'primevue/dialog'
    import ProgressSpinner from 'primevue/progressspinner'
    import InputText from 'primevue/inputtext'
    import Button from 'primevue/button'

    import { saveAs } from 'file-saver'
    import { Agendamento } from './../../middleware'
    export default {
        watch: {
            '$parent.selectedRow': function (val) {
                this.dsRash = ''
                this.model = val
                if (val?.id && !val.transacao_financeira) this.getDadosGuia(val)
            }
        },
        components: { Dialog, ProgressSpinner, InputText, Button },
        data () {
            return {
                options:{
                    status_chamada: {
                        "MC":"Médico Chamou",
                        "EA":"Em Andamento",
                        "PR":"Paciente Recusou",
                        "PS":"Paciente na Sala",
                        "DE":"Desligada"
                    }
                },
                model: {},
                dsRash: '',
                waiting: false,
                waitingDadosIds: [],
            }
        },
        methods: {
            executarGuia () {
                this.waiting = true
                Agendamento.executarGuia(this.model.id, this.dsRash).then(response => {
                    this.waiting = false
                    if (response.status == 200) {
                        this.$toast.success('Guia eletrônica executada', { duration: 3000 })
                        this.$parent.dialogVisualizar = false
                        this.$parent.applyFilters()
                    } else if (response.status == 400) {
                        this.$toast.error('Chave inválida', { duration: 3000 })
                    }
                })
            },
            imprimirGuia () {
                this.waiting = true
                Agendamento.imprimirGuia(this.model.id).then(response => {
                    this.waiting = false
                    if (response.status == 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            saveAs(blob, `guia-atendimento-${ this.model.id }.pdf`)
                        }
                    }
                })
            },
            getDadosGuia(model) {
                if (!model) return

                this.waitingDadosIds.push(model.id)
                Agendamento.getInfoGuiaByHorario(model?.id).then(response => {
                    this.waitingDadosIds = this.waitingDadosIds.filter(id => id !== model.id)
                    if (response.status === 200) {
                        model.transacao_financeira = response.data
                        model.cdGuiaEletronica = model?.transacao_financeira?.cd_transacao_financeira
                    }
                })
            }
        }

    }
</script>
