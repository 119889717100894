<template lang="pug">
    Dialog.dialogEditar(header="Informações sobre o erro" :visible.sync='$parent.dialogErro' :modal='true')
        form(@submit.prevent='handleSubmit()')
            .ta-center(v-if='waiting')
                ProgressSpinner(strokeWidth='2')
            .p-grid.p-fluid.p-align-end(v-else)
                .p-col-12.p-md-2(style="align-self:start")
                    label.form-label Cód. guia:
                    InputText(type='text' v-model='model.cdGuiaEletronica' :readonly='true')
                .p-col-12.p-md-10(v-if='model.ie_tipo_agenda == "C"')
                    label.form-label Procedimento:
                    InputText(type='text' v-model='model.nm_procedimento_f' :readonly='true')
                .p-col-12.p-md-10(v-if='model.ie_tipo_agenda != "C"')
                    label.form-label Procedimento:
                    div(v-for="proc in model.cd_procedimentos" v-if="model.cd_procedimentos.length > 0")
                        InputText(type='text' :value="`${proc.cd_cbhbm} - ${proc.nm_procedimento}`" :readonly='true')
                    InputText(v-else type='text' v-model='model.nm_procedimento_f' :readonly='true')
                .p-col-12.p-md-9
                    label.form-label Paciente:
                    InputText(type='text' v-model='model.nm_paciente' :readonly='true')
                .p-col-12.p-md-3.ta-center
                    label.form-label Situação no MEDCLUB:
                    p.mtd-badge.co1(:class="{ co3: model.ie_status == 'R', co5: model.ie_status == 'A' }") {{ model.ie_status_f }}

                .p-col-12.p-md-6
                    label.form-label Id Externo:
                    InputText(type='text' v-model='model.id_externo' :readonly='true')
                .p-col-12.p-md-6
                    label.form-label Status Integração:
                    Dropdown(v-model='dataSend.ie_status_integracao' :options='options.ie_status'
                        optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione')
                .p-col-12.p-md-6
                    label.form-label Atualização Integração:
                    InputText(type='text' v-model='model.dt_atualizado_integracao_f' :readonly='true')
                .p-col-12.p-md-6
                    label.form-label Tentativas Integração:
                    InputText(type='text' v-model='model.nr_tentativas_integracao' :readonly='true')
                .p-col-12.p-md-12
                    label.form-label Mensagem Integracao:
                    InputText(type='text' v-model='model.ds_mensagem_integracao' :readonly='true')
                .p-col-12.p-md-4
                    Button(icon='jam jam-save' label='Salvar')

</template>

<style lang="scss">
    .agendamento-listar {
        .dialogEditar {
            @media all and (min-width: 577px) {
                max-width: 640px;
                width: 100%;
            }
            .p-inputtext[disabled=disabled] {
                color: #000;
                font-weight: 600;
                background-color: #ddd;
            }
            .mtd-badge {
                margin-top: 6px;
                font-size: 14px;
                padding: 6px 12px;
            }
            .p-progress-spinner {
                width: 80px;
                margin: 40px auto;
            }
        }
    }
</style>

<script>
    import Dialog from 'primevue/dialog'
    import ProgressSpinner from 'primevue/progressspinner'
    import InputText from 'primevue/inputtext'
    import Dropdown from 'primevue/dropdown'
    import Button from 'primevue/button'

    import moment from 'moment'
    import { Agendamento } from './../../middleware'
    export default {
        components: { Dialog, InputText, ProgressSpinner, Dropdown, Button },
        watch: {
            '$parent.selectedRow': function (val) {
                if (this.$parent.dialogErro) {

                    this.model = val
                    this.model.nm_procedimento_f = `${ this.model.cd_cbhpm } - ${ this.model.nm_procedimento }`
                    this.model.dt_atualizado_integracao_f = moment(this.model.dt_atualizado_integracao).format("hh:mm DD/MM/YYYY")

                    let obj = Object.assign({}, this.model);
                    this.dataSend['ie_status_integracao'] = obj.ie_status_integracao
                }
            }
        },
        data () {
            return {
                model: {},
                dataSend: {
                    ie_status_integracao: null,
                },
                options: {
                    ie_status: [
                        { value: 'CO', text: 'Confirmado Manualmente' },
                        { value: 'R', text: 'Reservado' }
                    ]
                },
                waiting: false
            }
        },
        methods: {
            handleSubmit(){
                this.waiting = true
                Agendamento.alterarStatusIntegracaoHorario(this.model.id, this.dataSend).then((response) => {
                    this.waiting = false
                    if (response.status == 200){
                        let ie_status_obj = {}
                        this.options.ie_status.forEach(e => { if (e.value) ie_status_obj[e.value] = e.text })
                        
                        this.$parent.selectedRow.ie_status_integracao = this.dataSend['ie_status_integracao']
                        this.$parent.selectedRow.ie_status_integracao_f = ie_status_obj[this.dataSend['ie_status_integracao']]
                        //this.$parent.applyFilters()
                        this.$toast.success('Horário salvo com sucesso', { duration: 3000 })
                    } else if (response.status == 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string')
                                this.$toast.error(response.data.detail, { duration: 3000 })
                            else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        } else Object.values(response.data).forEach(errorMsg => {
                            if (typeof errorMsg == 'object')
                                this.$toast.error(errorMsg[0], { duration: 3000 })
                            else this.$toast.error(errorMsg, { duration: 3000 })
                        })
                    }
                })
            },
        }
    }
</script>